import { useContext, useEffect, useRef, useState } from "react";

import { Card } from "@emisgroup/ui-card";
import { WidgetContentEnum } from "../../../Enum/WidgetContentEnum";
import WidgetErrorContent from "../../WidgetErrorContent/WidgetErrorContent";
import WidgetEnum from "../../../Enum/WidgetEnum";
import { ProgressSpinner } from "@emisgroup/ui-progress-indicator";
import NotificationComponent, {
  INotificationProps,
  NotificationContent
} from "../../CustomComponents/NotificationComponent/Index";
import { WidgetContext, WidgetContextValue } from "../../../Contexts/Widget/WidgetContext";
import HttpRequest, { IRequestProps } from "../../../Utilities/ApiHelper/HttpRequest";
import { SessionContext, SessionContextValue } from "../../../Contexts/Session/SessionContext";
import { SettingsButton } from "./SettingsButton";
import { DismissAllButton } from "./DismissAllButton";
import { ButtonGroup } from "@emisgroup/ui-button";
import { NotificationDataList } from "./NotificationDataList";
import { DispatchSystemtrayNotificationEvent } from "../../../Utilities/SystemTrayNotification/Index";
import { INotificationPayloadType } from "./INotificationPaylaodType";
import { NotificationActionType } from "../../../Enum/NotificationActionTypeEnum";
import { UserDetailsContext, UserDetailsContextValue } from "../../../Contexts/UserDetailsContext/UserDetailsContext";

import "./NotificationCard.scss";

export interface INotificationData {
  id: number;
  pharmacyId: number;
  typeId: number;
  typeName: string;
  count: number;
  increasedCount: number;
  updatedTime: Date | null;
  isActive: boolean;
  snoozeFreeTime: Date | null;
  userInRoleId: number;
  snoozedCount: number;
}

export const NotificationCard = () => {
  const { searchWidgetText, isSearchAvailable, widgetItem, setWidgetItem } =
    useContext<WidgetContextValue>(WidgetContext);
  const [notificationContentState, setNotificationContentState] = useState(WidgetContentEnum.PROGRESS_SPINNER);
  const { session } = useContext<SessionContextValue>(SessionContext);
  const { userDetails } = useContext<UserDetailsContextValue>(UserDetailsContext);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const filteredNotificationData = useRef<INotificationData[]>(undefined);
  const [updatedNotificationData, setUpdatedNotificationData] = useState<INotificationData[]>(undefined);
  const [isEnableClearButton, setIsEnableClearButton] = useState(true);
  const [notificationProps, setNotificationProps] = useState<INotificationProps>({
    content: NotificationContent.default,
    isOpen: isNotificationOpen,
    notificationType: "confirmation",
    onClose: () => {}
  });

  useEffect(() => {
    document.addEventListener("SystemTrayAction", systemTrayActionEventListener);
    return () => {
      document.removeEventListener("SystemTrayAction", systemTrayActionEventListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (updatedNotificationData != undefined) {
      filteredNotificationData.current = updatedNotificationData.filter(
        (i) => i.snoozeFreeTime == null || new Date(i.snoozeFreeTime) <= new Date()
      );

      if (filteredNotificationData.current.length === 0) {
        setIsEnableClearButton(false);
        return setNotificationContentState(WidgetContentEnum.NO_CONTENT);
      }
      UpdateScriptWidgetItems();
      setNotificationContentState(WidgetContentEnum.VALID_CONTENT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedNotificationData, isEnableClearButton]);

  const systemTrayActionEventListener = (e: any) => {
    let systemTrayPayloadData: INotificationPayloadType = JSON.parse(e?.detail);
    switch (systemTrayPayloadData.SystemTrayNotificationActionType) {
      case NotificationActionType.ADD:
        updateNotification(systemTrayPayloadData.SystemTrayNotificationData);
        break;
      case NotificationActionType.GET:
        GetNotificationData();
        break;
      case NotificationActionType.SNOOZE:
        SnoozeNotification(systemTrayPayloadData);
        break;
      case NotificationActionType.DELETE:
        DeleteNotification(systemTrayPayloadData);
        break;
      case NotificationActionType.SNOOZEALL:
        SnoozeAllNotification(systemTrayPayloadData);
        break;
      case NotificationActionType.DELETEALL:
        DeleteAllNotification();
        break;
    }
  };

  useEffect(() => {
    if (session !== undefined && session.enabledNotifications !== "") {
      GetNotificationData();
    } else {
      setNotificationContentState(WidgetContentEnum.NO_CONTENT);
      setIsEnableClearButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Remove the notification data by id when user delete or navigating to module by clicking the item in notification widget
  const SetFilteredNotificationList = (
    deleteNotificationId: number,
    isError: boolean,
    systemTrayPayloadData: INotificationPayloadType,
    isNotificationActioned: boolean
  ) => {
    if (!isError) {
      const filteredNotificationList = filteredNotificationData.current?.filter((i) => i.id != deleteNotificationId);
      filteredNotificationData.current = filteredNotificationList;
      setUpdatedNotificationData(filteredNotificationList);
    }

    isNotificationActioned
      ? TriggerNotificationPayload(
          NotificationActionType.ACTIONED,
          systemTrayPayloadData.SystemTrayNotificationData,
          isError
        )
      : TriggerNotificationPayload(
          systemTrayPayloadData.SystemTrayNotificationActionType,
          systemTrayPayloadData.SystemTrayNotificationData,
          isError
        );
  };

  const TriggerNotificationPayload = (
    type: NotificationActionType,
    notificationData: INotificationData[],
    isError: boolean
  ) => {
    const payloadData: INotificationPayloadType = {
      SystemTrayNotificationActionType: type,
      SystemTrayNotificationData: notificationData,
      HasError: isError
    };
    DispatchSystemtrayNotificationEvent(payloadData);
  };

  const HandleApiError = () => {
    setNotificationProps({
      content: NotificationContent.error,
      isOpen: true,
      notificationType: "error",
      onClose: () => setIsNotificationOpen(false)
    });
  };

  const GetNotificationData = async () => {
    const getNotificationDataRequestProps: IRequestProps = {
      session,
      method: "GET",
      url: "/notifications"
    };

    await HttpRequest(getNotificationDataRequestProps)
      .then((res) => {
        let formatNotificationData: INotificationData[] = [];
        res.data?.map((notificationData) => {
          formatNotificationData.push({
            id: notificationData.id,
            pharmacyId: notificationData.attributes.pharmacyId,
            typeId: notificationData.attributes.typeId,
            typeName: notificationData.attributes.typeName,
            count: notificationData.attributes.count,
            increasedCount: notificationData.attributes.increasedCount,
            updatedTime: notificationData.attributes.updatedTime,
            isActive: notificationData.attributes.isActive,
            snoozeFreeTime: notificationData.attributes.snoozeFreeTime ?? null,
            userInRoleId: notificationData.attributes.userInRoleId,
            snoozedCount: notificationData.attributes.snoozedCount
          });
        });
        FilterActiveNotification(formatNotificationData);
      })
      .catch((err) => {
        TriggerNotificationPayload(NotificationActionType.GET, [], false);
        if (err.statusCode !== 404) {
          setIsNotificationOpen(true);
          HandleApiError();
          setNotificationContentState(WidgetContentEnum.ERROR_CONTENT);
          filteredNotificationData.current = undefined;
        } else {
          setIsEnableClearButton(false);
          setNotificationContentState(WidgetContentEnum.NO_CONTENT);
        }
      });
  };

  const FilterActiveNotification = async (notificationData: INotificationData[]) => {
    const filteredData = notificationData.filter((i) => new Date(i.snoozeFreeTime) <= new Date() && !i.isActive);
    if (filteredData.length != 0) {
      const systemPayload: INotificationPayloadType = {
        SystemTrayNotificationActionType: NotificationActionType.UNSNOOZE,
        SystemTrayNotificationData: null,
        HasError: false
      };

      const statusCode = await SnoozeAllNotification(systemPayload);

      if (statusCode !== 204) {
        notificationData = notificationData.filter((n) => !filteredData.some((f) => f.id === n.id));
      }
    }
    SetActiveNotificationData(notificationData);
  };

  const SetActiveNotificationData = (notificationData: INotificationData[]) => {
    TriggerNotificationPayload(NotificationActionType.GET, notificationData, false);
    setUpdatedNotificationData(notificationData);
  };

  const updateNotification = async (notificationData: INotificationData[]) => {
    const updateNotificationRequestProps: IRequestProps = {
      session,
      method: "PUT",
      url: "/notifications",
      data: {
        data: {
          attributes: {
            typeId: notificationData[0].typeId,
            count: notificationData[0].count,
            increasedCount: notificationData[0].increasedCount
          },
          type: "notification"
        }
      }
    };
    await HttpRequest(updateNotificationRequestProps)
      .then(() => {})
      .catch(() => {});
  };

  const DeleteNotification = async (systemTrayPayloadData: INotificationPayloadType) => {
    let isDeleteFail: boolean = false;
    const notificationId = systemTrayPayloadData.SystemTrayNotificationData[0].id;
    const deleteNotificationRequestProps: IRequestProps = {
      session,
      method: "DELETE",
      url: "/notifications",
      params: {
        notificationId
      }
    };
    await HttpRequest(deleteNotificationRequestProps)
      .then(() => {})
      .catch(() => {
        isDeleteFail = true;
        setIsNotificationOpen(true);
        HandleApiError();
      });
    SetFilteredNotificationList(notificationId, isDeleteFail, systemTrayPayloadData, false);
  };

  const DeleteAllNotification = async () => {
    let isDeleteAllFail: boolean = false;
    const deleteAllNotificationRequestProps: IRequestProps = {
      session,
      method: "DELETE",
      url: "/notifications"
    };
    await HttpRequest(deleteAllNotificationRequestProps)
      .then(() => {
        filteredNotificationData.current = [];
        setUpdatedNotificationData([]);
      })
      .catch(() => {
        isDeleteAllFail = true;
        setIsNotificationOpen(true);
        HandleApiError();
      });
    TriggerNotificationPayload(NotificationActionType.DELETEALL, [], isDeleteAllFail);
  };

  const SnoozeNotification = async (systemTrayPayloadData: INotificationPayloadType) => {
    const notificationId = systemTrayPayloadData.SystemTrayNotificationData[0].id;
    const notificationSnoozeTime = systemTrayPayloadData.SystemTrayNotificationData[0].snoozeFreeTime;
    let isSnoozeFail: boolean = false;
    let headers = {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
      NACSCode: session.nationalPracticeCode,
      SessionToken: session.sessionToken,
      Modules: session.modules.toString(),
      EnabledNotifications: session.enabledNotifications.toString(),
      snoozeLimit: session.snoozeLimit
    };
    const snoozeNotificationRequestProps: IRequestProps = {
      session,
      method: "PUT",
      url: "/notifications/snooze",
      headers: headers,
      data: {
        data: {
          attributes: {
            snoozeFreeTime: notificationSnoozeTime
          },
          id: notificationId.toString(),
          type: "snoozeRequestPayload"
        }
      }
    };

    await HttpRequest(snoozeNotificationRequestProps)
      .then(() => {
        filteredNotificationData.current = filteredNotificationData.current?.map((arr) => {
          if (arr.id == notificationId) {
            return {
              ...arr,
              isActive: false,
              snoozeFreeTime: notificationSnoozeTime
            };
          }
          return arr;
        });
        setUpdatedNotificationData(filteredNotificationData.current);
      })
      .catch(() => {
        isSnoozeFail = true;
        setIsNotificationOpen(true);
        HandleApiError();
      });
    TriggerNotificationPayload(
      NotificationActionType.SNOOZE,
      systemTrayPayloadData.SystemTrayNotificationData,
      isSnoozeFail
    );
  };

  const SnoozeAllNotification = async (systemTrayPayloadData: INotificationPayloadType) => {
    const notificationSnoozeAllTime =
      systemTrayPayloadData.SystemTrayNotificationData == null
        ? null
        : systemTrayPayloadData.SystemTrayNotificationData[0].snoozeFreeTime;
    let statusCode = 0;
    let headers = {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
      NACSCode: session.nationalPracticeCode,
      SessionToken: session.sessionToken,
      Modules: session.modules.toString(),
      EnabledNotifications: session.enabledNotifications.toString()
    };

    if (systemTrayPayloadData.SystemTrayNotificationActionType == NotificationActionType.SNOOZEALL) {
      headers["SnoozeLimit"] = session.snoozeLimit;
    }

    const snoozeAllNotificationRequestProps: IRequestProps = {
      session,
      method: "PUT",
      url: "/notifications/snooze",
      headers: headers,
      data: {
        data: {
          attributes: {
            snoozeFreeTime: notificationSnoozeAllTime
          },
          type: "snoozeRequestPayload"
        }
      }
    };
    await HttpRequest(snoozeAllNotificationRequestProps)
      .then((res) => {
        if (systemTrayPayloadData.SystemTrayNotificationActionType == NotificationActionType.SNOOZEALL) {
          filteredNotificationData.current = filteredNotificationData.current?.map((arr) => {
            arr.isActive = false;
            arr.snoozeFreeTime = notificationSnoozeAllTime;
            return arr;
          });
          setUpdatedNotificationData(filteredNotificationData.current);
          TriggerNotificationPayload(
            NotificationActionType.SNOOZEALL,
            systemTrayPayloadData.SystemTrayNotificationData,
            false
          );
        }
        statusCode = res.statusCode;
      })
      .catch((err) => {
        if (systemTrayPayloadData.SystemTrayNotificationActionType == NotificationActionType.SNOOZEALL) {
          TriggerNotificationPayload(
            NotificationActionType.SNOOZEALL,
            systemTrayPayloadData.SystemTrayNotificationData,
            true
          );
        }
        statusCode = err.statusCode;
        setIsNotificationOpen(true);
        HandleApiError();
      });
    return statusCode;
  };

  useEffect(() => {
    if (WidgetEnum.NOTIFICATIONS.toLowerCase() === searchWidgetText) {
      document.getElementById("systemtray-notification-card")?.scrollIntoView({ behavior: "smooth", block: "center" });
      isSearchAvailable.current = true;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchWidgetText]);

  const setNotificationListData = () => {
    filteredNotificationData.current = [];
    setUpdatedNotificationData([]);
    setIsNotificationOpen(true);
    setNotificationProps((notificationProps) => ({
      ...notificationProps,
      content: { title: "All notifications dismissed", text: "" },
      isOpen: true,
      notificationType: "confirmation",
      onClose: () => {
        setIsNotificationOpen(false);
      }
    }));
    TriggerNotificationPayload(NotificationActionType.DELETEALL, [], false);
    setNotificationContentState(WidgetContentEnum.NO_CONTENT);
    setIsEnableClearButton(false);
  };

  //Update the overdue and owings updated count in scripts widget to match with notification widget
  const UpdateScriptWidgetItems = () => {
    const updatedWidgetItems = widgetItem.map((item) => {
      return {
        ...item,
        widgetItem: item.widgetItem.map((widgetItem) => {
          const matchingNotification = updatedNotificationData.find(
            (notification) =>
              notification.typeName === widgetItem.attributes.name &&
              widgetItem.attributes.enabled &&
              widgetItem.attributes.count != notification.count
          );

          if (matchingNotification) {
            return {
              ...widgetItem,
              attributes: {
                ...widgetItem.attributes,
                count: matchingNotification.count
              }
            };
          }
          return widgetItem;
        })
      };
    });
    setWidgetItem(updatedWidgetItems);
  };

  let notificationContent = null;

  switch (notificationContentState) {
    case WidgetContentEnum.VALID_CONTENT:
      notificationContent = (
        <NotificationDataList
          notificationData={updatedNotificationData}
          setFilteredNotificationList={SetFilteredNotificationList}
        />
      );
      break;

    case WidgetContentEnum.NO_CONTENT:
      notificationContent = (
        <p data-dd-action-name="no-action-text" id="empty-script" className="empty-widget-item">
          No notifications
        </p>
      );
      break;

    default:
      notificationContent = (
        <ProgressSpinner className="spinner" data-testid="pending-progress-spinner" text={"Loading"} delay={1000} />
      );
      break;
  }
  return (
    <div className="systemtray-notification-container" data-testid="systemtray-notification">
      {isNotificationOpen && <NotificationComponent {...notificationProps} />}
      <Card
        data-dd-action-name="systemtray-notification-card"
        className={`widget-card ${WidgetEnum.NOTIFICATIONS.toLowerCase() === searchWidgetText ? "widget-highlight" : ""}`}
        data-testid="systemtray-notification-card"
        id="systemtray-notification-card"
      >
        <div className="systemtray-notification-header-container">
          <span className="systemtray-notification-header-text">Notifications</span>
        </div>
        {notificationContentState === WidgetContentEnum.ERROR_CONTENT ? (
          <WidgetErrorContent />
        ) : (
          <div>
            <div
              className="systemtray-notification-item-container"
              data-testid="systemtray-notification-list-item-container"
            >
              <span>{notificationContent}</span>
            </div>
            <div className="systemtray-notification-action-buttons">
              <ButtonGroup>
                {userDetails.isServiceDeskUser && <SettingsButton />}
                {isEnableClearButton && <DismissAllButton setNotificationListData={setNotificationListData} />}
              </ButtonGroup>
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};

export default NotificationCard;
